import React from 'react'; // eslint-disable-line no-unused-vars
import { Link } from 'gatsby-theme-material-ui';
import Layout from '../components/Layout';
import { List, Paragraph, Title } from '../components/Texts';

const Publications = () => (
  <Layout title="Publications">
    <Title>
      Publications
    </Title>

    <Paragraph>Mak Corp publie sous la signature <font color="#FF950E">« Makina Corpus »</font>.</Paragraph>
    <Paragraph>Retrouvez nos articles techniques sur <Link href="https://makina-corpus.com/blog">le blog</Link>.</Paragraph>

    <Paragraph>Nos experts contribuent régulièrement à différents logiciels libres&nbsp;:

      <List>
        <List item><Link href="https://github.com/makinacorpus/">https://github.com/makinacorpus/</Link></List>
        <List item><Link href="https://github.com/makina-maps/">https://github.com/makina-maps/</Link></List>
        <List item><Link href="https://leafletjs.com/plugins.html">https://leafletjs.com/plugins.html</Link></List>
        <List item><Link href="https://github.com/geotrekCE/">https://github.com/geotrekCE/</Link></List>
        <List item><Link href="https://github.com/terralego/">https://github.com/terralego/</Link></List>
        <List item><Link href="https://github.com/locokit/">https://github.com/locokit/</Link></List>
        <List item>…</List>
      </List>
    </Paragraph>

    <Paragraph>Nous publions parfois des présentations de nos conférences&nbsp;:
      <List>
        <List item><Link href="https://speakerdeck.com/makinacorpus">Speakerdeck</Link></List>
        <List item><Link href="https://www.slideshare.net/makinacorpus/">Slideshare</Link></List>
      </List>
    </Paragraph>

  </Layout>
);

export default Publications;
